@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Sarabun Regular'), local('Sarabun-Regular'), url(Sarabun-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Sarabun Light'), local('Sarabun-Light'), url(Sarabun-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: local('Sarabun Bold'), local('Sarabun-Bold'), url(Sarabun-Bold.ttf) format('truetype');
}

* {
    font-family: 'Sarabun', 'sans-serif';
}
