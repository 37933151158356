.Container{
    display: grid;
    grid-template-rows: auto 1fr; /* auto คือส่วนบน, 1fr คือเนื้อหาและ footer */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

/*Text*/
.text-color-primary { color: #0E572B; }
.text-color-secondary { color: #D9EDDE; }
.text-color-danger { color: #CB262D; }
.text-color-gray { color: #727178 }
.text-color-white { color: #ffffff }

/*Background*/
.bg-pri { background-color: #0E572B; }
.bg-second { background-color: #D9EDDE; }
.bg-second2 { background-color: #ebf3ed}
.bg-dan { background-color: #FDD1D9; }

/*Button*/
.btn{ border-radius: 20px; height: 50px; width: 100%; border: none; }
.btn-primary { background-color: #0E572B; }
.btn-primary:hover,active { background-color: #053b1a }
.btn-secondary { background-color: #D9EDDE }
.bg-danger { background-color: #CB262D }
.btn-warning { background-color: #e3b100 }
.btn-clear {
    background: none;
    border: none;
}

.btn-outline-primary{
    border: solid 1px #0E572B;
    color: #0E572B;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-outline-primary:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0E572B;
    color: white;
    border: none;
}

.btn-outline-danger{
    border: solid 1px red;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-outline-danger:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    color: white;
    border: none;
}

.btn:disabled{
    background: gray;
    color: white;
}
button:visited{
    background: gray;
    color: white;
}


/*Form*/
.form-control{
    height: 50px;
    border-radius: 14px;
    border: 1px solid #B4D1C1;
    background: #EBF3ED;
}
.form-control:hover{ background-color: #dfefe3;}
.form-control:focus {
    border-color: #053b1a;
    box-shadow: 0 1px 1px rgba(70, 176, 109, 0.6) inset, 0 0 8px rgba(5, 59, 26, 0.6);
    outline: 0 none;
}
.form-label{ font-size: 14px; }
input::placeholder{ font-size: 14px; }
.input-group-text{
    height: 50px;
    width: 56px;
    border-radius: 14px;
    border: 1px solid #B4D1C1;
    background: #EBF3ED;
    text-align: right;
}

.form-control:read-only{
    /* background: #8BB89F center/cover no-repeat url("../image/token.jpg"); */
    /*background: rgba(0, 255, 0, 0.5) center/cover no-repeat url('../image/token.jpg');*/
}
.form-control:disabled{
    background: none;
}


.icon{
    font-size: 20px;
    padding: 8px 10px;
    border-radius: 12px;
}

.icon-app{
    font-size: 20px;
    padding: 7px 10px;
    border-radius: 100px;
}

.icon-image{
    font-size: 12px;
}

.border-left-none{ border-left: none; }
.border-right-none{ border-right: none; }

.profileImage{
    border-radius: 50px;
    border: 2px solid #FFF;
    margin-right: 10px;
}

.card{
    padding: 10px 10px;
    border-radius: 20px;
    border:none;
}

.card-in-home{
    border-radius: 20px;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
    padding: 14px 0 0 14px;
    border: 4px solid #FFF;
    position: relative;
}

.btn-in-home{
    width: 75px;
    height: 30px;
    padding: 7px;
    font-size: 14px;
    padding: 0;
}

.title {
    font-size: 24px;
    color: #0E572B;
}

.title-app{
    font-size: 24px;
    background-color:#FFDFC3;
    border-radius: 10px;
}

.typeContainer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 14px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.link {
    color: black;
    margin-left: 20px;
}

.marker-label {
    background: white;
    padding: 12px;
    border-radius: 8px;
    position: absolute;
    left: -4.5rem;
    top: -7rem;
    font-family: 'Sarabun', 'sans-serif';
    white-space: nowrap;
    text-align: center;
}

/*.gmnoprint {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.gm-style-mtc-bbw {*/
/*    display: flex !important;*/
/*    flex-direction: row !important;*/
/*}*/

/*.gm-style-mtc-bbw .gm-style-mtc button {*/
/*    display: flex !important;*/
/*    align-items: center;*/
/*}*/

/*.gmnoprint button {*/
/*    padding: 15px !important;*/
/*}*/

/*.gm-bundled-control .gmnoprint div {*/
/*    background: transparent !important;*/
/*}*/

/*.gm-bundled-control .gmnoprint {*/
/*    left: -4px !important;*/
/*    top: 50px !important;*/
/*}*/

/*.gm-bundled-control .gmnoprint div div{*/
/*    margin-bottom: 5px !important;*/
/*}*/

/*.gm-bundled-control .gmnoprint button {*/
/*    border-radius: 100px !important;*/
/*    border: 1px solid lightgrey !important;*/
/*    background: white !important;*/
/*    width: 50px !important;*/
/*    height: 50px !important;*/
/*}*/

/*.gm-bundled-control .gmnoprint button:first-child {*/
/*    margin-bottom: 10px;*/
/*}*/



