.btn-filter-sensor {
    color: #fff !important;
    background-color: #6c757d;
    border-color: #6c757d ;;
    height: 40px !important;
}
.btn-filter-sensor:hover {
    opacity: 50%;
}

.react-datepicker-wrapper {
    width: 100%;
}