.farmer-user-container {
    height: 100%;
    overflow-y: auto;
}

/*.farmer-user-container .input-group-text {*/
/*    background-color: white;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    border-top: none !important;*/
/*    border-radius: 0;*/
/*}*/

/*.farmer-user-container .input-credential {*/
/*    border-top: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    padding: 10px;*/
/*    border-radius: 0;*/
/*}*/

.farmer-user-container .user-image-outer {
    position: relative;
}

.farmer-user-container .user-image-box {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 1px solid lightgrey;
    overflow: hidden;
}

.farmer-user-container .user-image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.farmer-user-container .user-image-box .icon-edit-user-image {
    padding: 5px 10px;
    background: #008F44;
    position: absolute;
    right: 0;
    bottom: 15px;
    z-index: 1;
    border-radius: 50px;
}

.station-selected .form-select-2 .css-13cymwt-control {
    height: 50px !important;
    border-radius: 14px;
    border: 1px solid #B4D1C1;
    background: #EBF3ED;
}
