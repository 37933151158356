.weather-container {
    background: linear-gradient(312.17deg, #296A43 38.31%, #419461 97.77%);
    border-radius: 20px;
    box-shadow: 0 15px 20px 0 #90BE8066;
    padding: 20px;
    color: white;
    height: fit-content;
}

.sensor-container .title {
    color: #0E572B;
    font-size: 20px;
    font-weight: 600;
}

.sensor-container .sensor-box {
    box-shadow: 0 0 25px 0 #ededf0;
    border-radius: 20px;
    margin: 5px;
}

.sensor-container .sensor-box.w-47 {
    width: 47%;
}

.card-thing-iot {
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding: 10px;
}
