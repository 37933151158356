.swal2-confirm ,.swal2-styled ,.swal2-default-outline{
    border-radius: 20px !important;
    width: 300px !important;
}

div:where(.swal2-container) h2:where(.swal2-title){
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}
