.innovation-container .list-container {
    flex: 0.95;
    display: flex;
    flex-direction: column;
}

.innovation-container .list-container .farm-list-container {
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 10px;
    /*padding-bottom: 10px;*/
}

.innovation-container .list-container .farm-list-container .farm-list-box {
    border-radius: 12px;
    box-shadow: 0 2px 5px 0 #191F3D0D;
    border: 1px solid #E9E8EB;
    position: relative;
}

.innovation-container .list-container .farm-list-container .farm-list-box .longan-img-box {
    width: 271px;
    height: 158px;
    overflow: hidden;
}

.innovation-container .list-container .farm-list-container .farm-list-box .farm-name {
    position: absolute;
    left: 0;
    bottom: 15px;
    background: #0E5590;
    color: white;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 5px 15px;
}

.innovation-container .list-container .farm-list-container .farm-list-box .tree-in-farm {
    position: absolute;
    right: 15px;
    bottom: 15px;
    background: #DEF7EC;
    border-radius: 25px;
    padding: 5px 15px;
    font-size: 10px;
}

.innovation-container .list-container .farm-list-container .farm-list-box .longan-img-box img {
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.data-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    width: 100%;
    color: #727178;
}
